import React from "react";
import "./styles.css";
// import ImageSection from '../GenericComponents/ImageSection'
// import banner from '../../images/section-banners/image_testimonials_web.png'
// import mobileBanner from '../../images/section-banners/image_testimonals_mobile.png'
// import Carousel from './Carousel'
// import { useTranslation } from "react-i18next";
// import { testimonialList } from "../../config";
import FadeInSection from "../FadeInSection";
// import { Container } from 'react-bootstrap'

export default () => {
  // const { t, i18n } = useTranslation()
  return (
    <FadeInSection>
    <section id="testimonials">
      {/*<ImageSection title={t('testimonials-title')} description={t('testimonials-subtitle')} bgUrl={banner} mobileBg={mobileBanner}/>*/}
      {/*<Container fluid>*/}
      {/*  <Carousel items={testimonialList[i18n.language]} />*/}
      {/*</Container>*/}
    </section>
    </FadeInSection>
  );
};
