import React from "react";
import "./styles.css";
import ContentSection from "../GenericComponents/ContentSection";
import ImageSection from "../GenericComponents/ImageSection";
import banner from "../../images/section-banners/image_organizers_web.png"
import mobileBanner from "../../images/section-banners/image_organizers_mobile.png"
import { useTranslation } from "react-i18next";
import { orgsOptions } from "../../config";
import FadeInSection from "../FadeInSection";
import { useHistory } from "react-router-dom";

export default () => {
  const { t, i18n } = useTranslation()
  const history = useHistory();
  const button = {
    icon: "icon-font_wedance-45",
    title: t('orgs-event-btn'),
    description: t('orgs-event-btn-desc'),
    onClick: () => {
      history.push("/customize-event");
    }
  }

  return (
    <FadeInSection>
      <section id="organizers">
        <ImageSection title={t('orgs-title')} description={t('orgs-subtitle')} bgUrl={banner} mobileBg={mobileBanner}/>
        <ContentSection listItems={orgsOptions[i18n.language]} button={button} disableIcon={true}/>
      </section>
    </FadeInSection>
  );
};

