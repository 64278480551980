import React, {useCallback} from "react";
import { Button, Container } from "react-bootstrap";
import '../GenericComponents/ContentSection/styles.css' //We must avoid this at all costs
import './styles.css'
import { useTranslation } from "react-i18next";
import useMedia from "use-media";

const containerStyle = { display: 'flex', justifyContent: "center", alignItems: "center", marginBottom: '20px'}
export default () => {
  const { t } = useTranslation()
  const isMobile = useMedia('(max-width:800px)')
  const handleContactUsClick = useCallback((e) => {
    e.preventDefault()
    window.open('mailto:support@izzyevent.com', '_blank')
  }, [])

  return (
    <Container className="contact-us-container" style={containerStyle}>
      <Button variant="outline-primary" className="generic-outline-btn" onClick={handleContactUsClick} style={{ display: 'flex', padding: '10px'}}>
          {!isMobile && (
                <i className={"button-icon icon-font_wedance-68"} aria-hidden="true" style={{display: 'inline-flex'}} />
          )}
          <p className="h5 button-title" style={isMobile ? { textAlign: 'center' }: { display: 'inline-flex', textAlign: 'center', marginLeft: '5px' }}>{t('contact-us-title')}</p>
      </Button>
    </Container>
  );
};
