import React from "react";
import "./styles.css";
import ImageSection from '../GenericComponents/ImageSection'
import banner from '../../images/section-banners/image_about_web.png'
import mobileBanner from '../../images/section-banners/image_about_mobile.png'
import Carousel from './Carousel'
import { useTranslation } from "react-i18next";
import { aboutTeamList } from "../../config";
import FadeInSection from "../FadeInSection";
import { Container } from "react-bootstrap";

export default () => {
  const { t, i18n } = useTranslation();
  return (
    <FadeInSection>
      <section id="about">
        <ImageSection 
          title={t('about-header')} 
          description={t('about-text')} 
          bgUrl={banner} 
          mobileBg={mobileBanner}
        />
        <Container fluid style={{ marginBottom: '5em'}}>
          <Carousel items={aboutTeamList[i18n.language]} />
        </Container>
      </section>
    </FadeInSection>
  );
};
