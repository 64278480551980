import React from "react";
import "./styles.css";
import {  Container } from "react-bootstrap";
import useMedia from 'use-media';

export default ({ title, description, mobileBg, bgUrl }) => {
    const isMobile = useMedia('(max-width:800px)')
    
    return (
        <div className="img-section" style={
            { backgroundImage: `url("${ isMobile ? mobileBg : bgUrl}")` }
        }>
            <Container fluid className={isMobile ? 'centered-mobile' : ''} style={ !isMobile ? { paddingLeft: '100px', paddingRight: '100px'} : {}}>
                <div style={{ paddingTop: isMobile ? '20px' : '80px', paddingBottom: isMobile ? '10px' :'25px'}}>
                    <div className="img-section-left">
                        <p className="h1 img-section-title" style={isMobile ? { textAlign: 'center'} : {}}>{title}</p>
                    </div>
                    { description && (<div className="img-section-left">
                        <p className="h3 img-section-description" style={isMobile ? { textAlign: 'center'} : {}}>{description}</p>
                    </div>
                    )}
                </div>
            </Container >
        </div >
    );
};
