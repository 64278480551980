export default {
    testimonials: [],
    translations: {
        en: {
            translation: {
                'we': 'We',
                'logo-right-text': 'DANCE, MANAGE AND SAVE </br> TIME WITH <b>IZZYEVENT</b>',
                'dance': 'Dance',
                'footer-description': 'Our mission is to save many hours for our clients, connecting at ease the dancing world.',
                'footer-support': 'IZZYEVENT SUPPORT',
                'footer-contact-dev': 'Contact developer',
                'footer-ideas': 'Ideas? Write us',
                'footer-faqs': 'FAQs',
                'footer-terms': 'Privacy Policy',
                'footer-tickets': 'Terms and Conditions',
                'footer-legal': 'Ticket Sales Policy',
                'footer-terms-website': "Terms of Website",

                'manage': 'Manage',
                'save-time': 'Save time',
                'download-free': 'Download for free',
                'download-also': 'Also available as desktop app',
                'im-dancer': "I'm a dancer",
                'im-org': "I'm an organizer",
                'setup-org': 'How to set up an Organizer Account?',
                'easiest-way': 'The easiest way to manage events, sell or buy tickets!',
                'easiest-desc': 'POWERFUL TOOLS FOR EVENT ORGANIZERS | EVENT PROMOTION | MORE THAN A TICKET PLATFORM | CHECK-IN SERVICE | LOW TICKET FEES | SECURE PAYMENTS',
                "nav-home": "Home",
                "nav-about": "About",
                "nav-dancers": "Dancers",
                "nav-orgs": "Organizers",
                "nav-videos": "Videos",
                "nav-testimonials": "Testimonials",
                "nav-contact": "Contact",
                "about-header": "About",
                "about-text":
                    "Izzyevent is free mobile app and advanced solutions for dancers and event organizers. We use the latest technology and design thinking in every process. Our goal is to create the best user experience providing easy, inexpensive and safe solutions. Izzyevent´s Team consists of experienced Mobile, Design, Customer Service professionals and knowledgeable event organizers.",
                "dancers-title": "DANCERS",
                "dancers-subtitle": "Easy to access and purchase your ticket.",
                "dancers-promoters-btn": "Promoters",
                "dancers-promoters-btn-desc": "Monitor usage of your promo code",
                "orgs-event-btn": "CHECK MORE",
                "orgs-event-btn-desc": "",
                "orgs-title": "ORGANIZERS",
                "orgs-subtitle": "Manage, Sell tickets and Promote your events!",
                "testimonials-title": "Testimonials",
                "testimonials-subtitle": "Thank you all: Dancers, Organizers, Teachers, DJ´s and event Teams for using Izzyevent app. Your feedback is extremely important, makes us grow and helps to create new functionalities. We believe that our continuous improvement will provide the best user experience for our community. Please feel free to share your opinion with us!",
                "contact-us-title": "Contact us",
                "contact-us-description": "",
                "contact-us-subject": "Subject",
                "contact-us-email": "E-mail",
                "contact-us-body": "Message",
                "contact-us-send": "Send",

                //FAQ Page
                "faq-title1": 'FREQUENTLY',
                "faq-title2": "ASKED QUESTIONS",
                "faq-subTitle": '',
                "faq-eventOrganizer": 'Organizers',
                "faq-eventDancers": 'Dancers',
                "faq-organizerBtnDesc": 'Select to read event organizer FAQ',
                "faq-dancerBtnDesc": 'Select to read event dancers FAQ',

                //Customize page
                "setup-title": "Powerful tools for successful events Plan your budget using tools that will save your time. Take control of your finances, what will pay you off in the future!",
                "setup-subTitle": "",
                "setup-individual": "Promote your event locally and worldwide Add an event to the Izzyevent app and make adverts for targeted audiences. Promote events in your local area or over the world. Check our list view and map option!",
                "setup-individual-desc": "Mobility and multi-platforms access Quick, intuitive management of events by your phone, tablet or desktop. Create events, workshops, parties within a few seconds. If you have not finished it yet, no problem - save the event as draft and continue later! Once published, you are ready to go.",
                "setup-brand": "Send notifications - ",
                "setup-brand-desc": "Promoters, employees or volunteers team Grant access for promoters to give ticket discounts. Furthermore promoters can see on the chart, usage of their own promo code.",
                "setup-shop": "Totally customizable - ",
                "setup-shop-desc": "Crete Event program Make your event an experience and advise participants unexpected changes. Allow users to check updates quickly by smartphones.",
                "setup-flexible": "Partnership marketing area - ",
                "setup-flexible-desc": "Ticket fees Service fees per sold ticket - absorb fee for yourself or pass fees to attendees. Sounds good? Check out how it works at Izzyevent app.",
                "setup-ux": "Post Festival agenda - ",
                "setup-ux-desc": " ",
                "show-pre": "Show Pre - parties agenda ",
                "show-pre-desc": "",
                "custom-analytics": "Use Custom analytics - ",
                "custom-analytics-desc": "",
                "setup-download-pdf": "Download pdf",
                "setup-pricing": "Pricing",

                //Promoter page
                "promoter-title": "PROMOTERS – SEE IN REAL TIME HOW MANY TIMES YOUR CODE WAS USED",
                "promoter-subtitle": "",
                "promoter-step-1": "<b>1. Promocode usage</b> Check how many people bought ticket using your promocode.",
                "promoter-step-2": "<b>2. Charts in your phone.</b> If you are a promoter/volunteer, monitor if you achieved goal of tickets sold. Real time updates, that are reflected in organizer´s profile as well.",
                "promoter-bottom-text": "If you have any doubts how it works, please contact us via email: ",
                //Setup page
                "setupPage-title": "Set up Organizer Account",
                "setupPage-subtitle": "How can you register and set up an organizer account?",
                "setupPage-step-1": "<b>1. Create your account by Izzyevent App.</b> You need to fulfill a form with your real data such as name, surname, address, cell phone number, email, vat or identification nr for your company.",
                "setupPage-step-2": "<b>2. Connect banking details.</b> In the second step we will ask you to connect your user account in our app with payment provider (Stripe - which is a global, secure payments provider that we use to process payments) . In that part you will need to provide official information (if you have company VAT identification nr etc.), your bank details in order to make payouts and receive funds once you sold some tickets etc.",
                "setupPage-step-3": "<b>3. Create festival.</b> During creation of the event you can add information such as title, dates, locations, line up, FAQs, tickets etc.",
                "setupPage-step-4": "",
                "setupPage-bottom-text": " During the above few steps we perform a user validation. \n" +
                    "              This takes 1 until 2 business days. During this period, we may contact you via email or phone if we need to clarify some of the provided information. \n" +
                    "              If you have any question, please feel free to contact us anytime via email:  "
            },
        },
        pt: {
            translation: {
                'we': 'We',
                'logo-right-text': 'DANCE, ORGANIZE E POUPE </br> TEMPO COM  <b>IZZYEVENT</b>',
                'dance': 'Dance',
                'manage': 'Manage',
                'footer-description': 'Our mission is to save many hours for our clients, connecting at ease the dancing world.',
                'footer-support': 'IZZYEVENT SUPPORT',
                'footer-contact-dev': 'Contact developer',
                'footer-ideas': 'Ideas? Write us',
                'footer-faqs': 'FAQs',
                'footer-terms': 'Privacy Policy',
                'footer-tickets': 'Terms and Conditions',
                'footer-legal': 'Ticket Sales Policy',
                'footer-terms-website': "Terms of Website",

                'save-time': 'Save time',
                'download-free': 'Descarregue gratuitamente',
                'download-also': 'Também disponível em versão Desktop',
                'im-dancer': "Eu Dançarino",
                'im-org': "Eu sou um Organizador",
                'setup-org': 'Como criar uma Conta de Organizador?',
                'easiest-way': 'A forma mais fácil de gerir eventos, vender ou comprar bilhetes!',
                'easiest-desc': 'FERRAMENTAS PODEROSAS PARA ORGANIZADORES DE EVENTOS  |   PROMOÇÃO DE EVENTOS   |   MAIS DO QUE UMA PLATAFORMA DE VENDA DE BILHETES   |   SERVIÇO DE CHECK-IN   |   TAXAS REDUZIDAS NA VENDA DE BILHETES    |    PAGAMENTOS SEGUROS',
                "nav-home": "Geral",
                "nav-about": "Sobre nós",
                "nav-dancers": "Dançarinos",
                "nav-orgs": "Organizadores",
                "nav-videos": "Vídeos",
                "nav-testimonials": "Testemunhos",
                "nav-contact": "Contactos",
                "about-header": "Sobre nós",
                "about-text":
                    "A Izzyvent é uma aplicação móvel gratuita que procura facilitar a vida tanto de quem dança como de quem organiza eventos. O nosso objetivo é criar uma aplicação fácil de manusear dando ao utilizador uma solução económica e segura. A IZZYVENT é composta por uma equipa de experientes profissionais nas áreas de Mobile Design, organização de eventos e apoio ao cliente.",
                "dancers-title": "DANÇARINOS",
                "dancers-subtitle": "Compra e acede com facilidade ao teu bilhete.",
                "dancers-promoters-btn": "Promotores",
                "dancers-promoters-btn-desc": "Acompanha o uso do teu Promocode",
                "orgs-event-btn": "OBTÉM UMA APP PERSONALIZADA PARA O TEU EVENTO",
                "orgs-event-btn-desc": "O poder do Design nas tuas mãos",
                "orgs-title": "ORGANIZADORES",
                "orgs-subtitle": "GERE, VENDE E PROMOVE OS TEUS EVENTOS",
                "testimonials-title": "TESTEMUNHOS",
                "testimonials-subtitle": "Queremos agradecer a todos os Dançarinos, Organizadores, Professores, Djs, e equipas de eventos por usarem a Izzyvent App. O vosso feedback é extremamente importante, faz-nos crescer e ajuda-nos a criar novas funcionalidades. Acreditamos que, através do nosso progresso, iremos igualmente dar cada vez mais uma melhor experiência para toda a nossa comunidade. Por favor, partilha a tua opinião connosco!",
                "contact-us-title": "Contacta-nos",
                "contact-us-description": "",
                "contact-us-subject": "Assunto",
                "contact-us-email": "E-mail",
                "contact-us-body": "Message",
                "contact-us-send": "Enviar",

                //FAQ Page
                "faq-title1": 'FREQUENTLY',
                "faq-title2": "ASKED QUESTIONS",
                "faq-subTitle": '',
                "faq-eventOrganizer": 'Organizers',
                "faq-eventDancers": 'Dancers',
                "faq-organizerBtnDesc": 'Select to read event organizer FAQ',
                "faq-dancerBtnDesc": 'Select to read event dancers FAQ',

                //Customize page
                "setup-title": "Customize my app event",
                "setup-subTitle": "",
                "setup-individual": "Aplicação do Festival,",
                "setup-individual-desc": "nós criamos a tua aplicação, personalizada, de acordo com as tuas necessidades e as do teu evento.",
                "setup-brand": "Marca Personalizada,",
                "setup-brand-desc": "Logotipo, adaptação de cores de acordo com a nossa oferta.",
                "setup-shop": "Loja on-line,",
                "setup-shop-desc": "gere e vende os teus produtos, bilhetes, etc. Os utilizadores podem encomendar, pagar antecipadamente e levantar quando chegarem ao evento.",
                "setup-flexible": "Funções Flexíveis,",
                "setup-flexible-desc": " Podes escolher funcionalidades, módulos de acordo com as tuas preferências.",
                "setup-ux": "Metodologias de experiência do utilizador,",
                "setup-ux-desc": "envolvemos design para todo o processo, integre produtos, incluindo branding, usabilidade e funções profissionais eficientes",
                "show-pre": "Show Pre - parties agenda ",
                "show-pre-desc": "keep all attendees informed and updated about prices, venues and pre-parties schedules",
                "custom-analytics": "Use Custom analytics - ",
                "custom-analytics-desc": "having access to charts and figures, observe your customers behaviours, make correct decisions and improve your business model",

                "setup-download-pdf": "Download pdf",
                "setup-pricing": "Preçario",

                //Promoter page
                "promoter-title": "PROMOTORES – VÊ EM TEMPO REAL QUANTAS VEZES O TEU CÓDIGO FOI USADO",
                "promoter-subtitle": "",
                "promoter-step-1": "<b>1. Uso de Promo Code </b> Verifica quantas pessoas compraram bilhetes usando o teu Código.",
                "promoter-step-2": "<b>2. Gráficos no teu telefone </b> Se fores um promotor/Voluntário, controla os teus objetivos nas vendas. Atualizações em tempo real que se refletem, igualmente, no perfil do organizador.",
                "promoter-bottom-text": "Se tiveres dúvidas como funciona, por favor entra em contacto connosco via e-mail: ",
                //Setup page
                "setupPage-title": "Criar uma conta de organizador",
                "setupPage-subtitle": "Como posso registar uma conta de Organizador?",
                "setupPage-step-1": "<b>1. Cria a tua conta em Izzyevent App. </b> Será necessário preencher um formulário com dados reais tais como nome, apelido, morada, número de telemóvel, e-mail, NIF",
                "setupPage-step-2": "<b>2. Ligação aos Dados Bancários. </b> No segundo passo é solicitada a associação do utilizador da Izzyevent App à plataforma de pagamentos Stripe(Stripe é uma conhecida plataforma a nível global que executa pagamentos seguros nos principais serviços on-line). A partir desse momento é necessária informação oficial (Se tiveres uma Empresa, a sua identificação fiscal), dados bancários para receber pagamentos das vendas efetuadas na Izzyevent App.",
                "setupPage-step-3": "<b>3. Criação de um evento.</b> Durante a criação de um evento poderás adicionar informação como título, datas, localização, Cartaz de Artistas, FAQs, Bilhetes, etc. Quando terminares basta apenas clickar em “Publicar” e está pronto.",
                "setupPage-step-4": "<b>4. Publica o teu festival na Izzyevent App.</b> Depois de seguir os passos anteriores e clicar em “Publicar”, vamos verificar todos os dados para evitar fraudes e eventos falsos.",
                "setupPage-bottom-text": " Durante os processos acima mencionados proceder-se-á a uma validação do utilizador. \n" +
                    "              Normalmente, demora 1 a 2 dias úteis. Serás contactado via e-mail, telefone etc. de forma a validarmos todos os dados necessários. \n" +
                    "              Se tiveres dúvidas, por favor, entra em contacto connosco a qualquer hora via e-mail: "
            },
        },
        fr: {
            translation: {
                'we': 'Nós',
                "logo-right-text": "DANSER, GÉRER ET GAGNER </br> DU TEMPS AVEC <b> IZZYEVENT </b>",
                'dance': 'On dance',
                'manage': 'On organise',
                'save-time': 'On gagne du temps',
                'download-free': 'Téléchargez gratuitement',
                'download-also': 'Desktop app disponible également',
                'im-dancer': "Je suis un(e) danseur(se) ",
                'im-org': "Je suis un(e) organisateur(rice) ",
                'setup-org': 'Comment créer un compte Organisateur',
                'easiest-way': 'Le moyen le plus facile pour organiser vos évènements, vendre ou acheter vos tickets!',
                'easiest-desc': 'LES MEILLEURES FONCTIONNALITES POUR ORGANISER VOS ÉVÈNEMENTS | PROMOTION | PLUS QU\'UNE PLATEFORME DE VENTE | ENREGISTREMENT NUMERIQUE | FAIBLE COMMISSION | PAIEMENT SECURISE ',
                "nav-home": "Home",
                "nav-about": "A propos",
                "nav-dancers": "Danseurs",
                "nav-orgs": "Organisateurs",
                "nav-videos": "Vidéos",
                "nav-testimonials": "Témoignages",
                "nav-contact": "Nous contacter",
                "about-header": "A propos",
                "about-text":
                    "Izzyevent est une application mobile gratuite qui propose des solutions avancées pour les danseurs et les organisateurs d'évènements. Nous utilisons les dernières technologies et design à chaque étape. Notre but est de fournir la meilleure expérience utilisateur en proposant des fonctionnalités simples, économiques et sûres. Notre équipe est composée de professionnels expérimentés du Mobile, du Design, de la Relation Client ainsi que d'irganisateurs d'évènements reconnus. ",
                "dancers-title": "DANSEURS",
                "dancers-subtitle": "Achetez et utilisez vos billets facilement.",
                "dancers-promoters-btn": "Ambassadeurs",
                "dancers-promoters-btn-desc": "Suivez en temps réel vos réservations",
                "orgs-event-btn": "Obtenez une application personnalisée pour vos évènements",
                "orgs-event-btn-desc": "Un design avancé à votre service",
                "orgs-title": "ORGANISATEURS",
                "orgs-subtitle": "Parfait pour organiser, vendre et promouvoir vos évènements! ",
                "testimonials-title": "Témoignages",
                "testimonials-subtitle": "Merci à tous : Danseurs, Organisateurs, Professeurs, DJ's et tous les intervenants pour utiliser Izzyevent app. Vos retour sont extrêment importants pour nous, pour évoluer et développer de nouvelles fonctionnalités. Nous avons pour but d'évoluer continuellement afin d'offrir la meilleure expérience à notre communauté. N'hésitez pas à nous faire part de vos remarques et commentaires ! ",
                "contact-us-title": "Nous contacter",
                "contact-us-subject": "Nom",
                "contact-us-email": "E-mail",
                "contact-us-body": "Objet",
                "contact-us-send": "Envoyer",
                'footer-terms-website': "Terms of Website",

                //FAQ Page
                "faq-title1": 'FRÉQUEMMENT',
                "faq-title2": "QUESTIONS POSÉES",
                "faq-subTitle": ' ',
                "faq-eventOrganizer": 'Organisateurs',
                "faq-eventDancers": 'Danseurs',
                "faq-organizerBtnDesc": ' ',
                "faq-dancerBtnDesc": ' ',

                //Customize page
                "setup-title": "Personnaliser mon événement d'application",
                "setup-subTitle": "",
                "setup-individual": "App Festival individuel,",
                "setup-individual-desc": "créez votre propre application personnalisée pour votre événement.",
                "setup-brand": "Marque personnalisée,",
                "setup-brand-desc": "sélection de logo et de couleurs adaptatives pour votre événement, en fonction de notre offre.",
                "setup-shop": "Boutique en ligne, vous pouvez télécharger,",
                "setup-shop-desc": "gérer et vendre des produits en créant votre propre boutique en ligne par application individuelle. Les utilisateurs peuvent commander des produits, payer avant puis récupérer sur place.",
                "setup-flexible": "Fonctions flexibles,",
                "setup-flexible-desc": "vous pouvez choisir des modules de fonctionnalités dans notre liste en fonction de vos préférences.",
                "setup-ux": "Méthodologies de l'expérience utilisateur,",
                "setup-ux-desc": "impliquer la conception de l'ensemble du processus, intégrer des produits, y compris l'image de marque, la convivialité et des fonctions pro efficaces",
                "show-pre": "Show Pre - parties agenda ",
                "show-pre-desc": "keep all attendees informed and updated about prices, venues and pre-parties schedules",
                "custom-analytics": "Use Custom analytics - ",
                "custom-analytics-desc": "having access to charts and figures, observe your customers behaviours, make correct decisions and improve your business model",
                "setup-download-pdf": "Télécharger le PDF",
                "setup-pricing": "Tarification",

                //Promoter page
                "promoter-title": "PROMOTEURS - SURVEILLEZ L'UTILISATION DE VOTRE PROMOCODE",
                "promoter-subtitle": "",
                "promoter-step-1": "<b> 1. Utilisation du code promotionnel </b> Vérifiez combien de personnes ont acheté un billet en utilisant votre code promotionnel.",
                "promoter-step-2": "<b> 2. Graphiques sur votre téléphone. </b> Si vous êtes un promoteur / bénévole, vérifiez si vous avez atteint l'objectif de billets vendus. Mises à jour en temps réel, qui se reflètent également dans le profil de l'organisateur.",
                "promoter-bottom-text": "Si vous avez des doutes sur son fonctionnement, veuillez nous contacter par e-mail:",
                //Setup page
                "setupPage-title": "Configurer un compte organisateur",
                "setupPage-subtitle": "Comment pouvez-vous vous inscrire et créer un compte organisateur?",
                "setupPage-step-1": "<b> 1. Créez votre compte par l'application Izzyevent. </b> Vous devez remplir un formulaire avec vos données réelles telles que nom, prénom, adresse, numéro de téléphone portable, e-mail, TVA ou numéro d'identification de votre entreprise.",
                "setupPage-step-2": "<b> 2. Connectez les informations bancaires. </b> Dans un deuxième temps, nous vous demanderons de connecter votre compte utilisateur dans notre application avec le fournisseur de paiement (Stripe - qui est un fournisseur de paiement mondial et sécurisé que nous utilisons pour traiter les paiements). Dans cette partie, vous devrez fournir des informations officielles (si vous avez le numéro d'identification TVA de la société, etc.), vos coordonnées bancaires afin d'effectuer des paiements et de recevoir des fonds une fois que vous avez vendu des billets, etc.",
                "setupPage-step-3": "<b> 3. Créer un festival. </b> Lors de la création de l'événement, vous pouvez ajouter des informations telles que le titre, les dates, les lieux, la programmation, les FAQ, les billets, etc.",
                "setupPage-step-4": "<b> 4. Publiez votre festival dans l'application Izzyevent. </b> Une fois que vous avez effectué les étapes précédentes et que vous avez cliqué sur «Publier», nous allons vérifier toutes les données afin d'éviter les fraudes et les faux événements.",
                "setupPage-bottom-text": " Au cours des quelques étapes ci-dessus, nous effectuons la validation de l'utilisateur. \n" +
                    "              Cela prend 1 à 2 jours ouvrables. Pendant cette période, nous pouvons vous contacter par e-mail ou par téléphone si nous avons besoin de clarifier certaines informations que vous avez fournies. \n" +
                    "              Si vous avez des doutes, veuillez nous contacter à tout moment par e-mail: "},

        },
        pl: {
            translation: {
                'we': 'We',
                'logo-right-text': 'TAŃCZ, ZARZĄDZAJ SWOIM EVENTEM I OSZCZĘDZAJ</br> CZAS Z <b>IZZYEVENT</b>',
                'dance': 'Dance',
                'manage': 'Manage',
                'save-time': 'Save time',
                'footer-description': 'Our mission is to save many hours for our clients, connecting at ease the dancing world.',
                'footer-support': 'IZZYEVENT SUPPORT',
                'footer-contact-dev': 'Contact developer',
                'footer-ideas': 'Ideas? Write us',
                'footer-faqs': 'FAQs',
                'footer-terms': 'Privacy Policy',
                'footer-tickets': 'Terms and Conditions',
                'footer-legal': 'Ticket Sales Policy',
                'footer-terms-website': "Terms of Website",
                'download-free': 'Pobierz za darmo',
                'download-also': 'Dostępna także jako aplikacja komputerowa',
                'im-dancer': "Jestem tancerzem",
                'im-org': "Jestem organizatorem",
                'setup-org': 'Jak założyć konto Organizatora?',
                'easiest-way': 'Najlepszy sposób zarządzania festiwalami oraz miejsce do kupna i sprzedaży biletów!',
                'easiest-desc': 'ZAAWANSOWANE NARZĘDZIA DLA ORGANIZATORÓW WYDARZEŃ ORAZ FESTIWALI | PROMOCJA FESTIWALI I EVENTÓW  | DUŻO WIĘCEJ NIŻ TYLKO PLATFORMA DO SPRZEDAŻY BILETÓW  | CHECK - IN SERWIS  | NISKIE OPŁATY SERWISOWE  | BEZPIECZNE PŁATNOŚCI',
                "nav-home": "Strona główna",
                "nav-about": "O nas",
                "nav-dancers": "Tancerze",
                "nav-orgs": "Organizatorzy",
                "nav-videos": "Wideo",
                "nav-testimonials": "Opinie",
                "nav-contact": "Kontakt",
                "about-header": "O nas",
                "about-text":
                    "Izzyevent to aplikacja mobilna oraz zaawansowane rozwiązania dla organizatorów festiwali, eventów oraz tancerzy. Naszym celem jest stworzenie miejsca przyjaznego użytkownikom poprzez użycie najnowszych technologii oraz designu. Możesz być pewny, że płatności dokonane w naszych aplikacjach są bezpieczne, szybkie wraz z niskimi opłatami serwisowymi. Nasz zespół Izzyevent to doświadczeni programiści, designerzy, doświadczona obsługa klienta. Współpracujemy także z Organizatorami tworzącymi popularne festiwale taneczne w Europie .",
                "dancers-title": "TANCERZE",
                "dancers-subtitle": "Łatwy dostęp i zakup biletu poprzez aplikację.",
                "dancers-promoters-btn": "Promotorzy",
                "dancers-promoters-btn-desc": "Sprawdź ile razy Twój promo code był użyty przy zakupie biletu",
                "orgs-event-btn": "ZDOBĄDŹ SPERSONALIZOWANĄ APLIKACJĘ DLA SWOJEGO EVENTU",
                "orgs-event-btn-desc": "Stwórz własny projekt",
                "orgs-title": "ORGANIZATORZY",
                "orgs-subtitle": "Zarządzanie, sprzedaż oraz reklama Twoich festiwali!",
                "testimonials-title": "Opinie",
                "testimonials-subtitle": "Chcielibyśmy podziękować wszystkim użytkownikom: Tancerzom, Organizatorom, Artystom, Didżejom oraz zespołom organizującym festiwale za korzystanie z naszej aplikacji Izzyevent. Dzięki Waszym opiniom jesteśmy w stanie rozwijać ten projekt, który powstał z miłości do tańca.  Jesteśmy wdzięczni za każdą sugestię, która pozwala nam tworzyć nowe funkcje, powiększając naszą ofertę z korzyścią dla całego świata tanecznego. Jesteśmy pewni, że Izzyevent jest brakującym elementem, który ułatwi i umili festiwale zarówno dla tancerzy jak i organizatorów, którzy wkładają ogrom pracy w przygotowania. Bardzo prosimy o podzielenie się z nami Waszą opinią, ponieważ każde zdanie jest dla nas bardzo ważne!",
                "contact-us-title": "Kontakt",
                "contact-us-subject": "Imię",
                "contact-us-email": "E-mail",
                "contact-us-body": "Message",
                "contact-us-send": "Wyślij",
                'footer-website': 'Terms of Website',

                //FAQ Page
                "faq-title1": 'FREQUENTLY',
                "faq-title2": "ASKED QUESTIONS",
                "faq-subTitle": '',
                "faq-eventOrganizer": 'Organizers',
                "faq-eventDancers": 'Dancers',
                "faq-organizerBtnDesc": 'Select to read event organizer FAQ',
                "faq-dancerBtnDesc": 'Select to read event dancers FAQ',

                //Customize page
                "setup-title": "SPERSONALIZOWANA APLIKACJA DLA TWOJEGO EVENTU",
                "setup-subTitle": "",
                "setup-individual": "Indywidualna aplikacja -",
                "setup-individual-desc": "stworzona w oparciu o indywidualne potrzeby dla konkretnego eventu lub festiwalu",
                "setup-brand": "Szybki kontakt z uczestnikami Twojego festiwalu -",
                "setup-brand-desc": "posiadając indywidualną aplikację możesz wysyłać powiadomienia bezpośrednio do uczestników Twojego festiwalu. Wyślij informacje o zmianach sal, zmianach w planie czy godzinach zajęć. Informacja ta pojawi się na głównej stronie indywidualnej aplikacji",
                "setup-shop": "Promuj swoje przyszłe festiwale i eventy partnerów -",
                "setup-shop-desc": "Dzięki specjalnie wyznaczonej strefie w aplikacji możesz z łatwością promować swoje przyszłe eventy a także pokazać eventy swoich partnerów. Pozwoli Ci to na dodatkową promocję a także na dodatkowy dochód z reklam innych festiwali",
                "setup-flexible": "Personalizowane logo i kolory ",
                "setup-flexible-desc": "oparte na bazie dostępnych opcji w naszym katalogu",
                "setup-ux": "Sklep online.",
                "setup-ux-desc": "W razie potrzeby tworzymy sklep online, w którym możesz promować i sprzedawać swoje produkty takie jak koszulki, leginsy, buty itp. Uczestnicy Twojego festiwalu z łatwością zamówią dany produkt z wyprzedzeniem a Ty będziesz wiedział ile produktów musisz wyprodukować. Oferujemy także opcję płatności z góry.",
                "show-pre": "Show Pre - parties agenda ",
                "show-pre-desc": "keep all attendees informed and updated about prices, venues and pre-parties schedules",
                "custom-analytics": "Use Custom analytics - ",
                "custom-analytics-desc": "having access to charts and figures, observe your customers behaviours, make correct decisions and improve your business model",
                "setup-download-pdf": "Pobierz pdf",
                "setup-pricing": "Cennik",

                //Promoter page
                "promoter-title": "PROMOTORZY",
                "promoter-subtitle": "",
                "promoter-step-1": "<b>1. Użycie promo kodu</b> Sprawdź ile razy Twój kod został wykorzystany przy kupnie biletów.",
                "promoter-step-2": "<b>2. Liczby i wykresy w Twoim telefonie.</b> Jeśli jesteś promotorem eventu, sprawdzaj na bieżąco w swoim telefonie wykresy oraz ile razy Twój kod został wykorzystany przy kupnie biletów. Od teraz masz pełną kontrolę w czasie rzeczywistym!",
                "promoter-bottom-text": "Jeśli masz wątpliwości jak to działa napisz do nas na ",
                //Setup page
                "setupPage-title": "ZAŁÓŻ KONTO JAKO ORGANIZATOR",
                "setupPage-subtitle": "Jak zarejestrować i założyć konto organizatora?",
                "setupPage-step-1": "<b>1. Założenie konta w aplikacji Izzyevent.</b> Pierwszym krokiem założenia konta jako organizator jest wypełnienie formularza z legalnymi danymi, takimi jak: imię, nazwisko, adres, nr telefonu, adres email, nr VAT, Regon lub inny nr identyfikacyjny, który posiadasz.",
                "setupPage-step-2": "<b>2. Uzupełnienie informacji bankowych (nr konta).</b> Drugim krokiem jest połączenie profilu użytkownika w aplikacji Izzyevent z kontem Stripe (Stripe jest to globalna firma, dostarczająca bezpieczne rozwiązania takie jak płatności online). Następnym krokiem będzie podanie wszystkich niezbędnych informacji do otrzymywania płatności od klientów.",
                "setupPage-step-3": "<b>3. Po uzupełnieniu wszystkich informacji, Izzyevent wykonuje weryfikację danych.</b> Proces ten trwa 1 do 2 dni roboczych. Podczas tego okresu możemy skontaktować się z Tobą w celu potwierdzenia danych (poprzez email lub rozmowę telefoniczną). ",
                "setupPage-step-4": "<b>4. Tworzenie festiwalu lub eventu..</b> W każdej chwili możesz stworzyć wersję roboczą festiwalu dodając np. Tytuł, daty, lokalizację, zaproszonych artystów, najczęściej zadawane pytania, bilety itp. Po zakończeniu możesz kliknąć “Opublikuj festiwal” po czym festiwal będzie widoczny dla tancerzy (jeśli Twój profil został zweryfikowany). Pamiętaj, że w każdej chwili możesz zaktualizować informacje na temat Twojego eventu.",
                "setupPage-bottom-text": " Jeśli masz jakiekolwiek wątpliwości skontaktuj się z nami poprzez email: ",
            },
        },
    },
};

export const aboutTeamList = {
    en: [
        {
            name: "Monika Kruk",
            role: "Co-founder, COO",
            comment:
                "“Izzyevent as its name, was created to make organizers life easier. We provide advanced solutions and whole event management in one place”",
        },
        {
            name: "Diogo Carvalho",
            role: "Co-founder, CEO",
            comment:
                "“With creativity, innovation and challenge we will contribute new paradigm of dance world, brining easy and intuitive app to the marketplace”",
        },
        {
            name: "Rui Domingues",
            role: "Head of UX DESIGN",
            comment:
                "“Izzyevent is challenging project, but we are sure that our design will be breakthrough in festivals design in the future”",
        },
    ],
    pt: [
        {
            name: "Monika Kruk Team",
            role: "Co-founder, COO",
            comment:
                "“Izzyevent, tal como o nome indica, foi criada para facilitar a vida dos organizadores. Colocamos numa app única soluções avançadas para a gestão de eventos”",
        },
        {
            name: "Diogo Carvalho",
            role: "Co-founder, CEO",
            comment:
                "“Com Criatividade, inovação e desafios criamos e contribuimos para um novo paradigma no mundo da dança, trazendo uma app fácil de utilizar e intuitiva”",
        },
        {
            name: "Rui Domingues",
            role: "Head of UX DESIGN",
            comment:
                "“Izzyevent é desafiante, mas estamos seguros que o nosso design será uma referência nos eventos e festivais do futuro”",
        },
    ],
    fr: [
        {
            name: "Monika Kruk Team",
            role: "Co-founder, COO",
            comment:
                "“Izzyevent comme son nom a été créé pour faciliter la vie des organisateurs. Nous fournissons des solutions avancées et une gestion complète des événements en un seul endroit”",
        },
        {
            name: "Diogo Carvalho",
            role: "Co-founder, CEO",
            comment:
                "“Avec créativité, innovation et défi, nous contribuerons à un nouveau paradigme du monde de la danse, en apportant une application simple et intuitive au marché”",
        },
        {
            name: "Rui Domingues",
            role: "Head of UX DESIGN",
            comment:
                "“Izzyevent est un projet stimulant, mais nous sommes sûrs que notre conception sera révolutionnaire dans la conception des festivals à l'avenir”",
        },
    ],
    pl: [
        {
            name: "Monika Kruk",
            role: "Co-founder, COO",
            comment:
                "“Izzyevent jak sama nazwa wskazuje zostało stworzone, aby ułatwić i umilić życie organizatorów. Naszą misją jest dostarczanie zaawansowanych rozwiązań oraz pełne zarządzanie całym procesem tworzenia festiwalu od a do z w jednym miejscu”",
        },
        {
            name: "Diogo Carvalho",
            role: "Co-founder, CEO",
            comment:
                "“Poprzez kreatywność, innowacyjne rozwiązania i wyzwania wierzymy, że zaznaczymy nowy paradygmat w świecie tanecznym, dostarczając łatwe oraz funkcjonalne rozwiązania w aplikacji mobilnej Izzyevent”",
        },
        {
            name: "Rui Domingues",
            role: "Head of UX DESIGN",
            comment:
                "“”",
        },
    ],
};

export const dancersOptions = {
    en: [
        {
            icon: "icon-font_wedance-03",
            title: "One App to Search, Buy tickets and Access events",
            description:
                "Check local and worldwide events, using effective filters or map view. Find detailed and updated information as agenda, location, teachers, artists and much more",
        },

        {
            icon: "icon-font_wedance-08",
            title: "More than a platform to buy tickets",
            description:
                "Buy, pay and access electronic tickets by Izzyevent app. Quick and easy Check-in",
        },

        {
            icon: "icon-font_wedance-07",
            title: "Notifications and promotions",
            description:
                "Add to favorites those events that you don’t want to miss and receive updates or notifications about promotions and ticket price changes, according to your interests.",
        },

        {
            icon: "icon-font_wedance-24",
            title: "Secure payments",
            description:
                "Using an international payment processing platform and event-specific authentication process, we keep your payments secure with low ticket fees. Izzyevent app accepts major credit cards: Visa, MasterCard, American Express etc.",
        },
    ],
    pt: [
        {
            icon: "icon-font_wedance-03",
            title: "Uma aplicação para Procurar, Comprar bilhetes e Aceder a Eventos",
            description:
                "Procura eventos a nível mundial através de poderosos filtros. Procura, detalhadamente, a informação mais atual tal como a agenda, a localização, os professores, os djs e muito, muito mais.",
        },

        {
            icon: "icon-font_wedance-08",
            title: "Muito mais que uma plataforma de venda de bilhetes",
            description:
                "Uma plataforma para te assistir no teu dia-a-dia dançante que também te pode ajudar a comprar bilhetes para os teus eventos favoritos.",
        },

        {
            icon: "icon-font_wedance-07",
            title: "Notifications and promotions",
            description:
                "Check worldwide events, using effective filters. Find out detailed, updated information as agenda, location, teachers, artists and much more.",
        },

        {
            icon: "icon-font_wedance-24",
            title: "Payments",
            description:
                "Using international payment processing platform and our events authentication process, we keep your payments secure with low ticket fees.",
        },
    ],
    fr: [
        {
            icon: "icon-font_wedance-03",
            title: "Une seule application pour rechercher, acheter vos places et accéder aux événements.",
            description:
                "Rechercher des événements internationaux via des filtres personnalisés. Trouver les dernières infos détaillées (programmations, lieux, professeurs, artistes, et bien plus encore).",
        },

        {
            icon: "icon-font_wedance-08",
            title: "Bien plus qu’une plateforme de vente de billets",
            description:
                "Achetez et utilisez vos tickets électroniques grâce à l’application. Enregistrement rapide et facile.",
        },

        {
            icon: "icon-font_wedance-07",
            title: "Notifications et promotions",
            description:
                "Ajoutez à vos favoris vos événements préférés. Recevez toutes les notifications, promotions et mises à jour des prix des billets.",
        },

        {
            icon: "icon-font_wedance-24",
            title: "Paiements",
            description:
                "Nous utilisons une plateforme de paiement internationale et sécurisée, tout en garantissant une faible commission.",
        },
    ],
    pl: [
        {
            icon: "icon-font_wedance-03",
            title: "Znajdź ulubiony festiwal oraz kup bilet przy użyciu jednej aplikacji",
            description:
                "Przeglądaj eventy z całego świata według własnych preferencji używając odpowiednich filtrów. Sprawdź szczegółowe informacje takie jak program festiwalu, lokalizacja, artyści występujący i wiele innych.",
        },

        {
            icon: "icon-font_wedance-08",
            title: "Dużo więcej niż tylko aplikacja do sprzedaży i zakupu biletów",
            description:
                "Bardzo szybki i łatwy proces check - in. Pokaż swój bilet z Qr kodem, który znajdziesz w aplikacji.",
        },

        {
            icon: "icon-font_wedance-07",
            title: "Notifications and promotions",
            description:
                "Dodaj do swoich “ulubionych” festiwale, które Cię interesują. Bądź na bieżąco i otrzymuj powiadomienia na temat promocji oraz zmian cen.",
        },

        {
            icon: "icon-font_wedance-24",
            title: "Płatności",
            description:
                "Współpracujemy z globalną firmą obsługującą płatności online, dzięki czemu możesz czuć się bezpiecznie płacąc kartą poprzez aplikację Izzyevent. Ponadto Organizatorzy festiwali przechodzą proces weryfikacji zanim event zostanie opublikowany.",
        },
    ],
};

export const orgsOptions = {
    en: [
        {
            icon: "icon-font_wedance-70",
            title: "Real time analytics",
            description: "Follow, analyze and promote events according to your trends. Check charts and statistics: sales (daily, monthly, yearly); gender; geographic dispersion; promoters performance. Using those tools may help you to increase sales based on detailed analysis.",
        },

        {
            icon: "icon-font_wedance-59",
            title: "Billing with Izzyevent - without any cost",
            description: "You can easily choose an option to create Invoices for attendees. Insert all needed financial info and invoices will be sent out to the users on your behalf. Update accounting books by exporting data from app to your email.",
        },

        {
            icon: "icon-font_wedance-08",
            title: "Deadlines - create all of those at once, for entire sales time",
            description: "Set up tickets with deadlines, prices will change automatically according to inserted dates. From now on you do not need to remember about it!",
        },

        {
            icon: "icon-font_wedance-31",
            title: "Easy check - in Service from Izzyevent App - for free!",
            description: "Select your team, choose your wristband colors, follow attendees who checked- in - everything in the real time, wherever you are. Your team scans tickets by their own smartphones or tablets.",

        }
    ],
    pt: [
        {
            icon: "icon-font_wedance-70",
            title: "Analíticas em Tempo Real",
            description: "Analisa, segue e promove eventos de acordo com as tendências. Consulta gráficos de: Vendas (diárias, mensais e anuais); Género; Dispersão geográfica; Performance dos promotores; Pagamentos.",
        },

        {
            icon: "icon-font_wedance-59",
            title: "Ferramentas poderosas para tornar os teus eventos um verdadeiro sucesso",
            description: "Faz o teu planeamento financeiro e envia contratos para os teus artistas com um simples click.",
        },

        {
            icon: "icon-font_wedance-71",
            title: "Mobilidade e multi-plataformas",
            description: "Rápida, gestão de eventos intuitiva na palma da tua mão usando o teu Telefone, Tablet ou Computador.",
        },

        {
            icon: "icon-font_wedance-31",
            title: "Izzy Check-in: recebe os teus participantes em grande estilo com a Izzyevent App",
            description: "Seleciona a tua equipa, as cores das pulseiras de segurança e segue, em tempo real, o progresso de check-in a qualquer momento e onde estiveres.",
        }
    ],
    fr: [
        {
            icon: "icon-font_wedance-70",
            title: "Analyses en temps réel",
            description: "Adaptez vos stratégies de vente en fonction des analyses et des tendances via une visualisation graphique : des ventes (quotidiennes, mensuelles ou annuelles), du genre, de la localisation géographique, des performances de vos ambassadeurs, des paiements.",
        },

        {
            icon: "icon-font_wedance-59",
            title: "Les meilleures fonctionnalités pour organiser vos événements",
            description: "Etablissez votre budget et partagez vos contrats avec tous vos artistes en un simple clic.",
        },

        {
            icon: "icon-font_wedance-71",
            title: "Une application nomade et multi-plateformes",
            description: "Une gestion rapide et intuitive de vos événements sur votre smartphone, tablette ou pc.",
        },

        {
            icon: "icon-font_wedance-31",
            title: "Service d’enregistrement en ligne via Izzyevent App",
            description: "Constituez votre équipe, choisissez les couleurs de vos bracelets, suivez l’évolution de votre événement en temps réel.",
        }
    ],
    pl: [
        {
            icon: "icon-font_wedance-70",
            title: "Dane o sprzedaży w czasie rzeczywistym",
            description: "Sprawdzaj na bieżąco wszystkie dane ze sprzedaży oraz wykresy: m.in. sprzedaż (dzienna, miesięczna, roczna), wykres pokazujący podział na płeć (bilety kupione przez mężczyzn lub kobiety), geograficzne pochodzenie uczestników, liczba biletów zakupiona przy użyciu konkretnego kodu promocyjnego, wykres ukazujący wypłaty.",
        },

        {
            icon: "icon-font_wedance-59",
            title: "Zaawansowane narzędzia i techniki pozwalające na stworzenie udanego eventu",
            description: "Zaplanuj swój budżet w naszej aplikacji, stwórz umowy dla artystów i wyślij je jednym kliknięciem.",
        },

        {
            icon: "icon-font_wedance-71",
            title: "Mobilność i dostęp z wielu urządzeń",
            description: "Zarządzaj swoim eventem w szybki i intuicyjny sposób poprzez telefon, tablet lub z komputera.",
        },

        {
            icon: "icon-font_wedance-31",
            title: "Łatwy proces check - in z aplikacji Izzyevent, bez konieczności instalowania innych aplikacji",
            description: "Przydziel osoby, które będą wykonywały check-in z poziomu swojej aplikacji, wybierz kolor opasek (kolor pojawi się na bilecie podczas check-in, co pozwoli twojemu zespołowi na szybszą obsługę uczestników), kontroluj proces check-in poza miejscem festiwalu (możesz sprawdzić ile osób już zostało zameldowanych).",

        }
    ],

}

export const testimonialList = {
    en: [{
        name: 'Monika Kruk Team',
        role: 'Festival Organizer',
        rate: 5,
        comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
    }, {
        name: 'Monika Again',
        role: 'Festival CEO',
        rate: 1,
        comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
    },
        {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }, {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }, {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }],
    pt:  [{
        name: 'AGAGA',
        role: 'Festival Organizer',
        rate: 5,
        comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
    }, {
        name: 'Monika Again',
        role: 'Festival CEO',
        rate: 1,
        comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
    },
        {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }, {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }, {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }],
    fr:  [{
        name: 'AGAGA',
        role: 'Festival Organizer',
        rate: 5,
        comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
    }, {
        name: 'Monika Again',
        role: 'Festival CEO',
        rate: 1,
        comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
    },
        {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }, {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }, {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }],
    pl:  [{
        name: 'Monika Kruk Team',
        role: 'Festival Organizer',
        rate: 5,
        comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
    }, {
        name: 'Monika Again',
        role: 'Festival CEO',
        rate: 1,
        comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
    },
        {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }, {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }, {
            name: 'Diogo Monika',
            role: 'Festival CTO',
            rate: 0,
            comment: "“Our passion to dance brings great value to our community. We create missing element which will raise with help of dancers and organizers”"
        }],
}

export const organizerFAQ = {
    en: [{
        question: 'How much does it cost to use Izzyevent app?',
        answer: 'Izzyevent app is free of cost. There is just a service fee per each sold ticket. You have two options, absorb the fee for yourself or pass fees to attendees.'
    }, {
        question: 'Does Izzyevent provide billing for invoice creation?',
        answer: 'Yes, you can choose the billing option, it is totally free of costs. Add legal information, tax etc. and we will send automatic invoices in case the buyer picked this option. (Please check terms and conditions agreement).'
    }, {
        question: 'Does check-in is available?',
        answer: 'Yes, if you sold tickets by Izzyevent app we give you access to free check-in service. You or your team can easily check-in participants by your smartphones or tablets. If you sold some part of tickets by other sources, we can export a list of participants, giving you the possibility to check-in all tickets. To align details contact us via support@izzyevent.com'
    }, {
        question: 'Can I sell tickets if I did not fulfill all legal information?',
        answer: 'To sell tickets you need to configure your organizer account with all needed information in order to receive payouts.'
    }, {
        question: 'How Izzyevent helps to manage my Event?',
        answer: 'Izzyevent provides end to end managing process for event organizers. You can sell tickets, check your sales and analytics in real time, set up automatic ticket deadlines, manage a volunteer team, give access to check in, add participants, plan your event budget and more.'
    },{
        question: 'How does it work to cancel or postpone my event?',
        answer: 'If you want to postpone your event, go to change the date in app. Event will appear with the watermark “postponed”. If you need to cancel an event, event will appear with the watermark “cancelled”. Please bear in mind that you need to reimburse buyers according to the law and your refund policy. Izzyevent fees are non refundable as per terms and conditions policy.'
    }],
    pt: [{
        question: 'Quanto custa lol?',
        answer: 'App is free of cost. We don’t have subscription plans, nevertheless we have handling ticket fees. Example: European cards: 100 eur original ticket price = 102,65 eur buyer price Non-European cards:100 eur original ticket price = 103,15 eur buyer price If you are an organizer and you want to know more, contact us wedancesupport@gmail.com'
    }]
}

export const dancerFAQ = {
    en: [{
        question: 'How can I purchase ticket by Izzyevent app?',
        answer: 'It is really easy, choose desired ticket, fulfill information, pay by card. You are going to receive ticket at Izzyevent app and confirmation via email.'
    }, {
        question: 'Can I ask refund by Izzyevent app?',
        answer: 'Izzyevent is a service provider that sells tickets in the name of Organizers and does not own event income. Contact organizer to request refund. Please note, that Izzyevent service tickets  fees are not refundable.'
    }, {
        question: 'Can I request invoice?',
        answer: 'If  you want to receive an Invoice, please request it during purchasing the ticket.'
    }, {
        question: 'Today I have a dancer account, can I become an organizer?',
        answer: 'Yes, if you want to become an organizer please contact us and we will change your account role in the app.'
    },{
        question: 'How does check- in work for couple passes?',
        answer: 'If you bought a couple pass, you don’t need to be present in the venue at the same time with second person. We handle couple tickets separately. It means once you purchase the couple pass, you will receive 2 tickets in your profile. You need to share the qr code with the second person from the couple pass.'
    },{
        question: 'What option Izzyevent provides for Promoters? ',
        answer: ' If you are a promoter with promo code granted by Organizer, you can check charts and figures related to ticket numbers sold with usage of your promo code.'
    }],
    pt: [{
        question: 'Quanto custa lol?',
        answer: 'App is free of cost. We don’t have subscription plans, nevertheless we have handling ticket fees. Example: European cards: 100 eur original ticket price = 102,65 eur buyer price Non-European cards:100 eur original ticket price = 103,15 eur buyer price If you are an organizer and you want to know more, contact us wedancesupport@gmail.com'
    }]
}