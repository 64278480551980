import React from "react";
import "./styles.css";
import ContentSection from "../GenericComponents/ContentSection";
import ImageSection from "../GenericComponents/ImageSection";
import banner from "../../images/section-banners/image_dancers_web.png"
import mobileBanner from '../../images/section-banners/image_dancers_mobile.png'
import { useTranslation } from "react-i18next";
import { dancersOptions } from "../../config";
import FadeInSection from "../FadeInSection";
import {useHistory} from "react-router-dom";

export default () => {
  const { t, i18n } = useTranslation()
  const history = useHistory();
  const button = {
    icon: "icon-font_wedance-69",
    title: t('dancers-promoters-btn'),
    description: t('dancers-promoters-btn-desc'),
    onClick: () => {
      history.push("/promoter");
    }
  }


  return (
    <FadeInSection>
      <section id="dancers">
        <ImageSection title={t('dancers-title')} description={t('dancers-subtitle')} bgUrl={banner} mobileBg={mobileBanner} />
        <ContentSection listItems={dancersOptions[i18n.language]} button={button} />
      </section>
    </FadeInSection>
  );
};

