import React from 'react'
import './styles.css'
import video from "../../images/izzyevent_website.mp4"
export default () => {
    const [isPlaying, setPlaying] = React.useState(false)

    const videoRef = React.useRef()

    const onVideoPause = e => {
        e.preventDefault()
        if (isPlaying) {
            videoRef.current.pause()
            return setPlaying(false)
        }

        videoRef.current.play()
        setPlaying(true)
    }


    return (
        <section id="video">
            <video onClick={onVideoPause} ref={videoRef} className="video-frame" width="100%" height="100%" controls>
                <source src={video} type="video/mp4" />
            </video>
        </section>
    )


}