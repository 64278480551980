import React, { useState, useEffect, useCallback } from "react";
import "./styles.css";
import { Nav, Navbar, Image, Row, Col, Container } from "react-bootstrap";
import logo from "../../images/image_logo_header.png";
import Scrollspy from "react-scrollspy";
import { useTranslation } from "react-i18next";
import useMedia from "use-media";
import { Link } from "react-router-dom";
import androidIconFile from "../../images/image_google_store@2x.png";
import iosIconFile from "../../images/image_apple_store@2x.png";

export const NAVBAR_HEIGHT = "70px";
export default function({
  disableMainNavs,
  languagesDisabled,
  displayAndroidAndIphoneIcons
}) {
  const [activeSection, setActiveSection] = useState("home");
  const { t, i18n } = useTranslation();
  const activeLang = i18n.language;
  const isMobile = useMedia("(max-width:800px)");
  const [hambugerOpen, setHambugerOpen] = useState(false);

  const handleClickOutside = useCallback(
    e => {
      const nav = document.getElementById("navbar");
      if (nav && !nav.contains(e.target) && hambugerOpen) {
        return setHambugerOpen(false);
      }
    },
    [hambugerOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("scroll", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("scroll", handleClickOutside, true);
    };
  }, [hambugerOpen, handleClickOutside]);

  const onSectionUpdate = item => {
    if (item) {
      setActiveSection(item.id);
    }
  };

  const onLanguageChange = lang => {
    if (isMobile) {
      setHambugerOpen(false);
    }
    i18n.changeLanguage(lang);
  };

  const onLinkClick = () => {
    setHambugerOpen(false);
  };

  function onContactUsClick(e) {
    e.preventDefault();
    window.open("mailto:support@izzyevent.com", "_blank");
  }

  return (
    <>
      <Scrollspy
        items={[
          "home",
          "about",
          "dancers",
          "organizers",
          "video",
          //"testimonials",
          "contact-us"
        ]}
        currentClassName="is-current"
        onUpdate={onSectionUpdate}
        style={{ margin: 0 }}
      >
        <Navbar
          id="navbar"
          variant="dark"
          className="navbar-custom"
          style={
            isMobile || disableMainNavs
              ? {
                  opacity: "0.95",
                  minHeight: NAVBAR_HEIGHT
                }
              : {
                  opacity: "0.75",
                  minHeight: NAVBAR_HEIGHT
                }
          }
          expand="xl"
          fixed="top"
          onToggle={expanded => setHambugerOpen(expanded)}
          expanded={hambugerOpen}
        >
          <Row style={{ width: "100%", justifyContent: "space-between" }}>
            <Container fluid>
              <Col xl={1} md={12} xs={12}>
                <Row>
                  <Col xs={10} md={11}>
                    <Link to="/">
                      <Image src={logo} />
                    </Link>
                  </Col>
                  <Col>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  </Col>
                </Row>
              </Col>

              {!disableMainNavs && (
                <Col xl={6} md={12} className="centered-nav">
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav
                      className="mr-auto"
                      style={
                        hambugerOpen && isMobile ? { marginTop: "10px" } : {}
                      }
                    >
                      <Nav.Link
                        style={isMobile ? { marginBottom: "5px" } : {}}
                        href="#home"
                        onClick={onLinkClick}
                        className={
                          activeSection === "home"
                            ? "navbar-link is-current"
                            : "navbar-link"
                        }
                      >
                        {t("nav-home")}
                      </Nav.Link>
                      <Nav.Link
                        style={isMobile ? { marginBottom: "5px" } : {}}
                        href="#about"
                        onClick={onLinkClick}
                        className={
                          activeSection === "about"
                            ? "navbar-link border-left is-current"
                            : "navbar-link border-left"
                        }
                      >
                        {t("nav-about")}
                      </Nav.Link>
                      <Nav.Link
                        style={isMobile ? { marginBottom: "5px" } : {}}
                        onClick={onLinkClick}
                        href="#dancers"
                        className={
                          activeSection === "dancers"
                            ? "navbar-link border-left is-current"
                            : "navbar-link border-left"
                        }
                      >
                        {t("nav-dancers")}
                      </Nav.Link>
                      <Nav.Link
                        style={isMobile ? { marginBottom: "5px" } : {}}
                        onClick={onLinkClick}
                        href="#organizers"
                        className={
                          activeSection === "organizers"
                            ? "navbar-link border-left is-current"
                            : "navbar-link border-left"
                        }
                      >
                        {t("nav-orgs")}
                      </Nav.Link>
                      <Nav.Link
                        style={isMobile ? { marginBottom: "5px" } : {}}
                        onClick={onLinkClick}
                        href="#video"
                        className={
                          activeSection === "video"
                            ? "navbar-link border-left is-current"
                            : "navbar-link border-left"
                        }
                      >
                        {t("nav-videos")}
                      </Nav.Link>
                      {/*<Nav.Link*/}
                      {/*  style={isMobile ? { marginBottom: '5px'}: {}}*/}
                      {/*  onClick={onLinkClick}*/}
                      {/*  href="#testimonials"*/}
                      {/*  className={*/}
                      {/*    activeSection === "testimonials"*/}
                      {/*      ? "navbar-link border-left is-current"*/}
                      {/*      : "navbar-link border-left"*/}
                      {/*  }*/}
                      {/*>*/}
                      {/*   {t("nav-testimonials")}*/}
                      {/*</Nav.Link>*/}
                      <Nav.Link
                        style={isMobile ? { marginBottom: "5px" } : {}}
                        onClick={onLinkClick}
                        href="#contact-us"
                        className={
                          activeSection === "contact-us"
                            ? "navbar-link border-left is-current"
                            : "navbar-link border-left"
                        }
                      >
                        {t("nav-contact")}
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Col>
              )}
              {disableMainNavs && <Col xl={6} md={6} />}
              {!languagesDisabled && (
                <Col
                  xl={3}
                  md={12}
                  className="centered-nav"
                  style={hambugerOpen && isMobile ? { marginTop: "23px" } : {}}
                >
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav
                      className="mr-auto"
                      style={
                        isMobile
                          ? { justifyContent: "center", flexDirection: "row" }
                          : {}
                      }
                    >
                      <Nav.Link
                        onClick={() => onLanguageChange("en")}
                        className={
                          activeLang === "en"
                            ? "navbar-link is-current"
                            : "navbar-link"
                        }
                      >
                        EN
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => onLanguageChange("pt")}
                        className={
                          activeLang === "pt"
                            ? "navbar-link is-current border-left"
                            : "navbar-link border-left"
                        }
                      >
                        PT
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => onLanguageChange("fr")}
                        className={
                          activeLang === "fr"
                            ? "navbar-link is-current border-left"
                            : "navbar-link border-left"
                        }
                      >
                        FR
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => onLanguageChange("pl")}
                        className={
                          activeLang === "pl"
                            ? "navbar-link is-current border-left"
                            : "navbar-link border-left"
                        }
                      >
                        PL
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Col>
              )}

              <Col
                xl={2}
                className="centered-nav"
                style={hambugerOpen && isMobile ? { marginTop: "18px" } : {}}
              >
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav
                    className="mr-auto"
                    style={
                      isMobile
                        ? { justifyContent: "center", flexDirection: "row" }
                        : {}
                    }
                  >
                    <Nav.Link
                      href="https://www.facebook.com/IzzyeventSolutions"
                      target="blank"
                    >
                      <i
                        style={{
                          paddingRight: "10px",
                          cursor: "pointer",
                          color: "white"
                        }}
                        className="button-icon icon-font_wedance-66"
                        aria-hidden="true"
                      />
                    </Nav.Link>
                    <Nav.Link
                      href="https://www.instagram.com/izzyevent_official/"
                      target="blank"
                    >
                      <i
                        style={{
                          paddingRight: "10px",
                          cursor: "pointer",
                          color: "white"
                        }}
                        className="button-icon icon-font_wedance-67"
                        aria-hidden="true"
                      />
                    </Nav.Link>
                    <Nav.Link onClick={onContactUsClick}>
                      <i
                        style={{
                          paddingRight: "10px",
                          cursor: "pointer",
                          color: "white"
                        }}
                        className="button-icon icon-font_wedance-68"
                        aria-hidden="true"
                      />
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Col>
              {displayAndroidAndIphoneIcons && !isMobile && !hambugerOpen && (
                <Col xl={2} className="centered-nav">
                  <img
                    src={iosIconFile}
                    alt={"ios app"}
                    style={{
                      height: "50px",
                      width: "180px",
                      marginLeft: 0,
                      cursor: "pointer"
                    }}
                  />
                  <img
                    src={androidIconFile}
                    alt={"android app"}
                    style={{
                      height: "50px",
                      width: "180px",
                      marginLeft: "10px",
                      cursor: "pointer"
                    }}
                  />
                </Col>
              )}
            </Container>
          </Row>
        </Navbar>
      </Scrollspy>
    </>
  );
}
