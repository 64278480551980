import React from "react";
import "./styles.css";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useMedia from "use-media";
import { useHistory } from "react-router-dom";

export default () => {
  const { t } = useTranslation();
  const isMobile = useMedia("(max-width:800px)");
  const isTablet = useMedia("(min-width:700px)");
  //Used to remove QrCode
  const isDesktop = useMedia("(min-width:1025px)");
  const history = useHistory();

  const onDancerBtnClick = e => {
    e.preventDefault();
    const target = document.querySelector("#dancers");
    if (target) {
      target.scrollIntoView({ smooth: true });
    }
  };

  const onSetupOrgClick = e => {
    e.preventDefault();
    history.push("/setup");
  };

  const onOrgBtnClick = e => {
    e.preventDefault();
    const target = document.querySelector("#organizers");
    if (target) {
      target.scrollIntoView({ smooth: true });
    }
  };

  //Reacts to isTableOrMobile media and provides the column configuration
  const mainColumnProps = React.useMemo(() => {
    if (isMobile) {
      return {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 8
      };
    }

    return {
      xs: 2,
      sm: 3,
      md: 7,
      lg: 7,
      xl: 7
    };
  }, [isMobile]);

  return (
    <>
      <section
        className={isMobile ? "home-mobile-section" : "home-section"}
        id="home"
      >
        <Container fluid className="home-container">
          <Row className="home-main-row">
            <Col {...mainColumnProps} id="column-main-one">
              {!isMobile ? (
                <Row>
                  <Col className="home-col1-logo" xs={!isMobile ? 4 : 12}>
                    <div className="home-logo" />
                  </Col>
                  <Col>
                    <Row className="home-col1-title1">
                      <p
                        className={"h1 subtitle"}
                        dangerouslySetInnerHTML={{
                          __html: t("logo-right-text")
                        }}
                      />
                    </Row>

                    <Row className="home-col1-btn-setup">
                      <Button
                        onClick={onSetupOrgClick}
                        variant="outline-primary button-text-col"
                        className="organizer-btn-home"
                      >
                        {" "}
                        {t("setup-org")}
                      </Button>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Container>
                  <Row style={{ marginBottom: "20px" }}>
                    <p className="h3 home-col1-title2">{t("easiest-way")}</p>
                  </Row>

                  <Row
                    style={
                      isTablet
                        ? { marginBottom: "20px", justifyContent: "center" }
                        : { justifyContent: "center" }
                    }
                  >
                    <div
                      className="phones"
                      style={{ height: "20em", backgroundPosition: "center" }}
                    />
                  </Row>

                  <Row
                    className="home-col1-btn-setup"
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      justifyContent: "center"
                    }}
                  >
                    <Button
                      onClick={onSetupOrgClick}
                      variant="outline-primary button-text-col"
                      className="organizer-btn-home-mobile"
                    >
                      {" "}
                      {t("setup-org")}
                    </Button>
                  </Row>

                  <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
                    <div className="ios-icon" />
                    <div className="android-icon" />
                  </Row>
                  <Row>
                    <p
                      className="h5 home-col1-subtitle2"
                      style={isMobile ? { lineHeight: "1.5" } : {}}
                    >
                      {t("easiest-desc")}
                    </p>
                  </Row>
                </Container>
              )}

              {!isMobile && (
                <div id="home-divider" style={{ marginTop: "220px" }} />
              )}

              {!isMobile && (
                <>
                  <Row>
                    <p className="h3 home-col1-title2">{t("easiest-way")}</p>
                  </Row>
                  <Row>
                    <p
                      className="h5 home-col1-subtitle2"
                      style={isMobile ? { lineHeight: "1.5" } : {}}
                    >
                      {t("easiest-desc")}
                    </p>
                  </Row>
                </>
              )}
              <Row className="home-col1-bottom-row">
                {/**@todo Make it with less with and a column in the middle */}
                <Col className="home-col1-dancer-btn" xs={12} md={6}>
                  <Button
                    variant="outline-primary button-text-col"
                    className="home-outline-btn"
                    onClick={onDancerBtnClick}
                  >
                    <p
                      className="h5 button-title"
                      style={isMobile ? { fontSize: "14px" } : {}}
                    >
                      {t("im-dancer")}
                    </p>
                  </Button>
                </Col>
                {/* <Col md={2} xs={2} /> */}
                <Col
                  className="home-col1-org-btn"
                  xs={12}
                  md={6}
                  style={isMobile && !isTablet ? { marginTop: "20px" } : {}}
                >
                  <Button
                    variant="outline-primary button-text-col"
                    className="home-outline-btn"
                    onClick={onOrgBtnClick}
                  >
                    <p
                      className="h5 button-title"
                      style={isMobile ? { fontSize: "14px" } : {}}
                    >
                      {t("im-org")}
                    </p>
                  </Button>
                </Col>
              </Row>
            </Col>

            {!isMobile && (
              <Col
                xs={1}
                sm={1}
                md={4}
                lg={4}
                xl={4}
                id="column-main-two"
                style={{ marginLeft: "4em" }}
              >
                <Row>
                  <Col xl={6}>
                    <Row>
                      <p className="h4 home-col2-title">{t("download-free")}</p>
                      <p className="h5 home-col2-subtitle">
                        {t("download-also")}
                      </p>
                    </Row>
                    <Row>
                      <div className="ios-icon" />
                      <div className="android-icon" />
                    </Row>
                  </Col>
                  <Col xl={6}>
                    {isDesktop && <div className="home-qrcode" />}
                  </Col>
                </Row>
                <Row>
                  <div className="phones" style={{ height: "30em" }} />
                </Row>
              </Col>
            )}
          </Row>
          {isMobile && <div style={{ marginTop: "40px" }} />}
        </Container>
      </section>
    </>
  );
};
