import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import {  Row, Col, Image } from "react-bootstrap";
import test from "../../images/image_placeholder_user.png";
import chunk from "lodash/chunk";

function CarouselItem({ name, role, comment }) {
  return (
    <Col xs={10} md={3}  key={"item-" + name} style={{ marginBottom: '20px'}}>
      <Row className="center-carousel">
        <Image className="carousel-image" src={test} width="100" height="100" />
      </Row>
      <Row className="center-carousel">
        <p className="h5 carousel-name">
          {name || "Unknown"}
        </p>
      </Row>
      <Row className="center-carousel">
        <p className="h6" style={{color: "#6600ff"}}>{role || "Unknown"}</p>
      </Row>
      <Row className="center-carousel">
        <p className="h5">{comment || ""}</p>
      </Row>
    </Col>
  );
}

export default ({ items }) => {
  const chunkedItems = chunk(items, 3);
  const [currentSlide, setSlide] = useState(0);

  const onChange = (index) => {
    setSlide(index);
  };

  const children = chunkedItems.map((rows, i) => {
    const first = rows[0];
    const second = rows[1];
    const third = rows[2];

    return (
      <Row className="center-carousel" key={"slide-" + i} style={{ justifyContent: "space-around"}}>
        {first && (
          <CarouselItem
            name={first.name}
            role={first.role}
            comment={first.comment}
          />
        )}
        {second && (
          <CarouselItem
            name={second.name}
            role={second.role}
            comment={second.comment}
          />
        )}
        {third && (
          <CarouselItem
            name={third.name}
            role={third.role}
            comment={third.comment}
          />
        )}
      </Row>
    );
  });

  return (
    <>
      <Row className="carousel-container">
        <Carousel
          onChange={onChange}
          showIndicators={false}
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          swipeable={false}
          infiniteLoop
          autoPlay
          interval={3000}
          transitionTime={200}
          selectedItem={currentSlide}
          className="carousel-inner"
        >
          {children}
        </Carousel>
      </Row>
    </>
  );
};
