import React from "react";
import "./styles.css";
import { Row, Col, Container, Button } from "react-bootstrap";
import useMedia from 'use-media';

export default ({ listItems, button }) => {
    const isMobile = useMedia('(max-width:800px)')

    return (
        <div className="generic-section">
            <Container fluid="md">
                <Row className="generic-left">
                    {
                        listItems.map((item) => (
                            <Col xs={12} md={6} key={item.title}>
                                <Row className="generic-row">
                                    <Col xs={2} md={1}>
                                        <i className={"generic-icon " + item.icon} aria-hidden="true" />
                                    </Col>

                                    <Col>
                                        <p className="h5 generic-title">{item.title}</p>
                                        <p className="h6 generic-description">{item.description}</p>
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                </Row>
                <div className="generic-center">
                    <Button variant="outline-primary" className="generic-outline-btn" onClick={button.onClick}>
                        <Row xs={12}>
                            {!isMobile && (<Col className="button-middle-icon" xs={2} md={2} lg={2}>
                                <i className={"button-icon " + button.icon} aria-hidden="true" />
                            </Col>
                            )}

                            <Col className="button-text-col" xs={isMobile ? 12 : 10} lg={ isMobile ? 12 : 10} md={isMobile ? 12 : 10}>
                                <p className="h5 button-title" style={isMobile ? { textAlign: 'center' }: {}}>{button.title}</p>
                                <p className="h6 button-description" style={isMobile ? { textAlign: 'center' }: {}}>{button.description}</p>
                            </Col>
                        </Row>
                    </Button>
                </div>
            </Container>
        </div>
    );
};