import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./icons.css";
import "./App.css";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "./pages/index";
import { Redirect } from "react-router";
import Loadable from "react-loadable";

const loading = () => <></>;

const Faq = Loadable({
  loader: () => import("./pages/faq"),
  loading
});

const Setup = Loadable({
  loader: () => import("./pages/setup"),
  loading
});
const CustomizeEvent = Loadable({
  loader: () => import("./pages/customize-event"),
  loading
});

const Promoter = Loadable({
  loader: () => import("./pages/promoter"),
  loading
});

const FestivalDetails = Loadable({
  loader: () => import("./pages/festival-details"),
  loading
});

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      console.log("Google analytics initialized.");
      ReactGA.initialize("UA-171523260-1");
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Index />
        </Route>
        <Route path="/customize-event">
          <CustomizeEvent />
        </Route>
        <Route path="/setup">
          <Setup />
        </Route>
        <Route path="/promoter">
          <Promoter />
        </Route>
        <Route path="/faq">
          <Faq />
        </Route>
        <Route path={"/event/:id"}>
          <FestivalDetails />
        </Route>
        <Route
          path="*"
          render={() => {
            return <Redirect to="/" />;
          }}
        />
      </Switch>
    </Router>
  );
}

export default App;
