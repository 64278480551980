import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer'
import ContactUs from '../components/ContactUs';
import Testimonials from '../components/Testimonials';
import OrganizerSection from '../components/OrganizerSection';
import DancersSection from '../components/DancersSection';
import AboutSection from '../components/AboutSection';
import HomeSection from '../components/HomeSection';
import VideoSection from '../components/VideoSection';
import { Container } from 'react-bootstrap';

export default function () {
    return (
        <Container id="app" fluid style={{ padding: 0}}>
            <Navbar />
            <HomeSection />
            <AboutSection />
            <DancersSection />
            <OrganizerSection />
            <VideoSection />
            <Testimonials />
            <ContactUs />
            <Footer />
        </Container>
    )
}