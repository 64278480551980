/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback} from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import "./styles.css";
import banner from "../../images/image_logo@2x.png"
import useMedia from 'use-media';
import { Link } from 'react-router-dom'
import termsFile from '../../docs/terms.pdf'
import legalFile from '../../docs/legal.pdf'
import ticketsFile from '../../docs/tickets.pdf'
/** @todo Change the file into the correct file and remove this TODO after **/
import termsOfWebsiteFile from '../../docs/tickets.pdf'
import {useTranslation} from "react-i18next";

export default function Footer() {
  const isMobile = useMedia('(max-width:800px)')
  const { t } = useTranslation()

  const handleContactDevClick = useCallback((e) => {
    e.preventDefault()
    window.open('mailto:support@izzyevent.com?subject=Contact Developer', '_blank');
  }, [])

  const handleWriteUsClick = useCallback((e) => {
    e.preventDefault()
    window.open('mailto:support@izzyevent.com?subject=Ideas', '_blank');
  }, [])

  return (
    <footer className="footer">
      <Container fluid="xl" className="footer-container">
        <Row className="footer-row-description">
          <Col xs={12} md={5} xl={5}>
            <Row>
              <img className="footer-banner" alt="banner" src={banner} />
              <p className="footer-banner-description">
                {t('footer-description')}
              </p>
            </Row>
          </Col>
          <Col xl={4}/>
          <Col md={5} xl={3} xs={12}>
            <p className="h4 footer-title">{t('footer-support')}</p>
            <a className="h6 footer-faq-item"  onClick={handleContactDevClick}>> {t('footer-contact-dev')}</a>
            <a className="h6 footer-faq-item" onClick={handleWriteUsClick}>
              > {t('footer-ideas')}
            </a>
          </Col>
        </Row>
        <div className="footer-divider"/>
        <Row className="footer-row-nav">
          <Col xs={12} xl={9} md={9}>
            <Nav style={isMobile ? { fontSize: '8px' } : { fontSize: '11px'}}>
              <Nav.Item>
                <Link to="/faq">
                  <Nav.Link as={"div"} className="footer-link">
                    {t('footer-faqs')}
                  </Nav.Link>
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="footer-link" href={termsFile} download>
                  {t('footer-terms')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="footer-link" href={ticketsFile} download>
                  {t('footer-tickets')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="footer-link" href={legalFile} download>
                  {t('footer-legal')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="footer-link" href={termsOfWebsiteFile} download>
                  {t('footer-terms-website')}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          {/* <Col md={3} xl={3} xs={12}>
            <div style={ isMobile ? { fontSize: '8px', textAlign: 'center'} : { fontSize: '14px'}}>
              2020 WeDance | Registered trademark | All rights reserved
            </div>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
}
